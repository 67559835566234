import axios from 'axios';
import getAuthToken from './getAuthToken';

const axiosInt = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: 'Bearer ' + getAuthToken(),
  },
});

axiosInt.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    )
);

export default axiosInt;
