import {
  Box,
  Card,
  Tooltip,
  Typography,
  Container,
  styled,
  useTheme,
} from '@mui/material';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import JWTLogin from '../LoginJWT';

import { useTranslation } from 'react-i18next';
import Logo from 'src/components/Logo';
import Scrollbar from 'src/components/Scrollbar';
import Header from './header';

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 0 0 0 440px;clear

  }
  width: 100%;
  display: flex;
  align-items: center;
`
);

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: ${theme.colors.alpha.white[100]};
    width: 440px;
`
);

const SidebarContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(6)};
`
);

const CardImg = styled(Card)(
  ({ theme }) => `
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['border'])};
    position: absolute;

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(33)};
`
);

const graduatesData = [
  {
    id: 1,
    fullname: 'Şəbnəm Süleymanlı',
    image:
      'https://www.timeacademy.az/_next/image?url=%2Fimages%2Fstudents%2Fshabnam_suleymanli_alumni.png&w=256&q=75',
    result: '8.0',
    link: 'https://www.timeacademy.az/alumni',
    styles: {
      width: 80,
      height: 80,
      left: 0,
      top: 40,
    },
  },
  {
    id: 2,
    fullname: 'Şahin Quliyev',
    image:
      'https://www.timeacademy.az/_next/image?url=%2Fimages%2Fstudents%2Fshahin_quliyev_alumni.png&w=256&q=75',
    result: '7.5',
    link: 'https://www.timeacademy.az/alumni',
    styles: {
      width: 90,
      height: 90,
      left: 80,
      top: 0,
    },
  },
  {
    id: 3,
    fullname: 'Çinarə Kazımlı',
    image:
      'https://www.timeacademy.az/_next/image?url=%2Fimages%2Fstudents%2Fchinara_kazimli.png&w=256&q=75',
    result: '7.0',
    link: 'https://www.timeacademy.az/alumni',
    styles: {
      width: 110,
      height: 110,
      top: -162,
      left: 170,
    },
  },
  {
    id: 4,
    fullname: 'Namiq Məmmədhüseyn',
    image:
      'https://www.timeacademy.az/_next/image?url=%2Fimages%2Fstudents%2Fnamiq_mammadhuseyn_alumni.png&w=256&q=75',
    result: '7.0',
    link: 'https://www.timeacademy.az/alumni',
    styles: {
      width: 70,
      height: 70,
      top: -162,
      left: 259,
    },
  },
  {
    id: 5,
    fullname: 'Çingiz Əsədzadə',
    image:
      'https://www.timeacademy.az/_next/image?url=%2Fimages%2Fstudents%2Fchingiz_asadzade.png&w=256&q=75',
    result: '8.0',
    link: 'https://www.timeacademy.az/alumni',
    styles: {
      width: 80,
      height: 80,
      left: 0,
      top: 40,
    },
  },
  {
    id: 6,
    fullname: 'Mətanət Hacızadə',
    image:
      'https://www.timeacademy.az/_next/image?url=%2Fimages%2Fstudents%2Fmatanat_hacizade.png&w=256&q=75',
    result: '7.5',
    link: 'https://www.timeacademy.az/alumni',
    styles: {
      width: 90,
      height: 90,
      left: 80,
      top: 0,
    },
  },
  {
    id: 7,
    fullname: 'Fidan Tahirova',
    image:
      'https://www.timeacademy.az/_next/image?url=%2Fimages%2Fstudents%2Ffidan_tahirova.png&w=256&q=75',
    result: '8.0',
    link: 'https://www.timeacademy.az/alumni',
    styles: {
      width: 110,
      height: 110,
      top: -162,
      left: 170,
    },
  },
  {
    id: 8,
    fullname: 'Səmra Mustafayeva',
    image:
      'https://www.timeacademy.az/_next/image?url=%2Fimages%2Fstudents%2Fsamra_mustafayeva.png&w=256&q=75',
    result: '7.0',
    link: 'https://www.timeacademy.az/alumni',
    styles: {
      width: 70,
      height: 70,
      top: -162,
      left: 259,
    },
  },
];

function LoginCover() {
  const { method } = useAuth() as any;
  const { t } = useTranslation();

  const theme = useTheme();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
  };

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Header />
      <Content>
        <SidebarWrapper
          sx={{
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <Scrollbar>
            <SidebarContent>
              <Box mt={6}>
                <TypographyH1
                  variant="h1"
                  sx={{
                    mb: 7,
                  }}
                >
                  {t('Graduates')}
                </TypographyH1>
                <Slider {...settings}>
                  <Box
                    sx={{
                      position: 'relative',
                      width: 300,
                      height: 200,
                    }}
                  >
                    {graduatesData
                      .slice(0, 4)
                      .map(({ id, fullname, image, result, link, styles }) => (
                        <Tooltip
                          key={id}
                          arrow
                          placement="top"
                          title={fullname}
                        >
                          <Box
                            sx={{
                              ...styles,
                              position: 'relative',
                            }}
                          >
                            <CardImg
                              sx={{
                                width: '100%',
                                height: '100%',
                              }}
                            >
                              <a href={link} target="_blank" rel="noreferrer">
                                <img
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                  alt={fullname}
                                  src={image}
                                />
                              </a>
                            </CardImg>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                position: 'absolute',
                                bgcolor: process.env.REACT_APP_PRIMARY_COLOR,
                                color: 'white',
                                bottom: 0,
                                right: 0,
                                fontSize: '11px',
                                px: '6px',
                                py: '1.5px',
                                borderRadius: 1,
                              }}
                            >
                              {result}
                            </Typography>
                          </Box>
                        </Tooltip>
                      ))}
                  </Box>
                  <Box
                    sx={{
                      position: 'relative',
                      width: 300,
                      height: 200,
                    }}
                  >
                    {graduatesData
                      .slice(4, 8)
                      .map(({ id, fullname, image, result, link, styles }) => (
                        <Tooltip
                          key={id}
                          arrow
                          placement="top"
                          title={fullname}
                        >
                          <Box
                            sx={{
                              ...styles,
                              position: 'relative',
                            }}
                          >
                            <CardImg
                              sx={{
                                width: '100%',
                                height: '100%',
                              }}
                            >
                              <a href={link} target="_blank" rel="noreferrer">
                                <img
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                  alt="Şəbnəm Süleymanlı"
                                  src={image}
                                />
                              </a>
                            </CardImg>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                position: 'absolute',
                                bgcolor: process.env.REACT_APP_PRIMARY_COLOR,
                                color: 'white',
                                bottom: 0,
                                right: 0,
                                fontSize: '11px',
                                px: '6px',
                                py: '1.5px',
                                borderRadius: 1,
                              }}
                            >
                              {result}
                            </Typography>
                          </Box>
                        </Tooltip>
                      ))}
                  </Box>
                </Slider>
                <Typography
                  variant="subtitle1"
                  sx={{
                    my: 3,
                  }}
                >
                  {t(
                    'Our graduates continue their studies in prestigious universities of different countries of the world.'
                  )}
                </Typography>
              </Box>
            </SidebarContent>
          </Scrollbar>
        </SidebarWrapper>
        <MainContent>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
            maxWidth="sm"
          >
            <Card
              sx={{
                p: 4,
                my: 4,
              }}
            >
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1,
                  }}
                >
                  {t('login.signIn')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{ mb: 3 }}
                >
                  {t('login.info')}
                </Typography>
              </Box>
              {method === 'JWT' && <JWTLogin />}
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default LoginCover;
