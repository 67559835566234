import { Navigate, RouteObject } from 'react-router';

import Authenticated from 'src/components/Authenticated';

import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';

import dashboardsRoutes from './dashboards';
import applicationsRoutes from './applications';
import managementRoutes from './management';
import accountRoutes from './account';
import baseRoutes from './base';

const router: RouteObject[] = [
  {
    path: '',
    children: [...accountRoutes, ...baseRoutes],
  },
  {
    path: 'dashboard',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        children: dashboardsRoutes,
      },
      // {
      //   path: "applications",
      //   children: applicationsRoutes,
      // },
      // {
      //   path: "management",
      //   children: managementRoutes,
      // },
      {
        path: '*',
        element: <Navigate to="/dashboard" />,
      },
    ],
  },
];

export default router;
