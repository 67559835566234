import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import StudentDetails from 'src/content/dashboards/student-details';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const Automation = Loader(
  lazy(() => import('src/content/dashboards/Automation'))
);
const Analytics = Loader(
  lazy(() => import('src/content/dashboards/Analytics'))
);
const HomeworkPage = Loader(
  lazy(() => import('src/modules/homework/pages/homework'))
);
const LessonsPage = Loader(
  lazy(() => import('src/modules/lessons/pages/lessons'))
);
const ProfilePage = Loader(
  lazy(() => import('src/modules/profile/pages/profile'))
);
const Lessons = Loader(lazy(() => import('src/modules/lessons/pages/lessons')));
const Banking = Loader(lazy(() => import('src/content/dashboards/Banking')));
const Commerce = Loader(lazy(() => import('src/content/dashboards/Commerce')));
const Expenses = Loader(lazy(() => import('src/content/dashboards/Expenses')));
const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));
const Finance = Loader(lazy(() => import('src/content/dashboards/Finance')));
const Fitness = Loader(lazy(() => import('src/content/dashboards/Fitness')));
const HealthcareDoctor = Loader(
  lazy(() => import('src/content/dashboards/Healthcare'))
);
const HealthcareHospital = Loader(
  lazy(() => import('src/content/dashboards/Healthcare/HospitalView'))
);
const Helpdesk = Loader(lazy(() => import('src/content/dashboards/Helpdesk')));
const Learning = Loader(lazy(() => import('src/content/dashboards/Learning')));
const Monitoring = Loader(
  lazy(() => import('src/content/dashboards/Monitoring'))
);
const Tasks = Loader(lazy(() => import('src/content/dashboards/Tasks')));
const Students = Loader(lazy(() => import('src/content/dashboards/Students')));

const dashboardsRoutes = [
  // {
  //   path: "",
  //   element: <Analytics />,
  // },
  // {
  //   path: 'students',
  //   element: <Students />,
  // },
  // {
  //   path: 'students/:studentId',
  //   element: <StudentDetails />,
  // },
  // {
  //   path: 'automation',
  //   element: <Automation />,
  // },
  // {
  //   path: 'analytics',
  //   element: <Analytics />,
  // },
  {
    path: '',
    element: <HomeworkPage />,
  },
  {
    path: 'lessons',
    element: <LessonsPage />,
  },
  {
    path: 'profile',
    element: <ProfilePage />,
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" />,
  },
  // {
  //   path: 'banking',
  //   element: <Banking />,
  // },
  // {
  //   path: 'commerce',
  //   element: <Commerce />,
  // },
  // {
  //   path: 'expenses',
  //   element: <Expenses />,
  // },
  // {
  //   path: 'crypto',
  //   element: <Crypto />,
  // },
  // {
  //   path: 'finance',
  //   element: <Finance />,
  // },
  // {
  //   path: 'fitness',
  //   element: <Fitness />,
  // },
  // {
  //   path: 'healthcare',
  //   children: [
  //     {
  //       path: '',
  //       element: <Navigate to="hospital" replace />,
  //     },
  //     {
  //       path: 'hospital',
  //       element: <HealthcareHospital />,
  //     },
  //     {
  //       path: 'doctor',
  //       element: <HealthcareDoctor />,
  //     },
  //   ],
  // },
  // {
  //   path: 'helpdesk',
  //   element: <Helpdesk />,
  // },
  // {
  //   path: 'learning',
  //   element: <Learning />,
  // },
  // {
  //   path: 'monitoring',
  //   element: <Monitoring />,
  // },
  // {
  //   path: 'tasks',
  //   element: <Tasks />,
  // },
];

export default dashboardsRoutes;
