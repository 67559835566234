import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import store from 'src/store';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { AuthProvider } from 'src/contexts/JWTAuthContext';

import { QueryClient, QueryClientProvider } from 'react-query';
import { AlertProvider } from './shared/contexts/Alert';
import Alert from './shared/components/Alert';

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AlertProvider>
      <HelmetProvider>
        <Provider store={store}>
          <SidebarProvider>
            <BrowserRouter>
              <ScrollTop />
              <AuthProvider>
                <App />
              </AuthProvider>
            </BrowserRouter>
          </SidebarProvider>
          <Alert />
        </Provider>
      </HelmetProvider>
    </AlertProvider>
  </QueryClientProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
