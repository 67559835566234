const enJSON = {
  homework: {
    name: 'Homework',
  },
  lessons: {
    name: 'Lessons',
    paidLessons: 'paid lessons',
    lessonTime: 'Lesson time',
  },
  group: {
    name: 'Group',
  },
  teacher: {
    name: 'Teacher',
  },
  resources: {
    name: 'Resources',
  },
  profile: {
    name: 'Profile',
  },
  languageSwitcher: {
    name: 'language switcher',
  },
  profil: {
    name: 'profil',
  },
  signOut: {
    name: 'sign out',
  },
  inputs: {
    labels: {
      changePassword: 'change password',
      changeEmail: 'change email',
      currentPassword: 'current password',
      password: 'password',
      passwordConfirm: 'password confirm',
      email: 'email',
      name: 'name',
      surname: 'surname',
      gender: 'gender',
      university: 'university',
      interests: 'interests',
      birthOfDate: 'birth of date',
      number: 'number',
      biography: 'biography',
      task: 'task',
      selectTask: 'select task',
      comment: 'comment',
    },
    placeholders: {},
  },
  yup: {
    currentPassword: 'current password is requierd',
    password: {
      required: 'password is requierd',
      min: 'password is requierd',
    },
    passwordConfirm: {
      required: 'confirm password is required.',
      correctly: 'does not match the repeat password',
    },
    email: {
      required: 'email is required',
      correctly: 'email not entered correctly',
    },
    inputs: {
      name: 'name is required',
      surname: 'surname is required',
      gender: 'gender is required',
      university: 'university is required',
      subject: 'subject is required',
      room: 'room is required',
      lessonStarting: 'lesson starting is required',
      lessonEnding: 'lesson ending is required',
      duration: 'duration is required',
      title: 'title is required',
      percentage: 'percentage is required',
      link: 'link is required',
      biography: 'biography is required',
      task: 'task is required',
      question: 'comment is required',
    },
  },
  status: 'status',
  joined: 'Joined',
  notJoined: 'Not Joined',
  modal: {
    areYouSureYouWantYoDelete: 'Are you sure you want to delete?',
  },
  messages: {
    accessToYourHomework:
      'Access to your homework assignments has been restricted due to an unpaid fee.',
    unpaidLessonCount: 'unpaid lesson count',
    noHomeworkYet: 'No homework yet!',
    addComment: 'Add comment',
    hardWorkPaysOff: 'Hard work pays off!',
    youHaveCompleted: 'You have completed',
    ofYourHomework: 'of your homework',
    noCommentYet: 'No comment yet!',
    noLessonsYet: 'No lessons yet!',
    noResourcesYet: 'No resources yet!',
  },
  login: {
    signIn: 'sign in',
    info: 'fill in the fields below to sign into your account.',
    forgetPassword: 'forget password ?',
    signUp: 'sign up here',
  },
  buttons: {
    confirm: 'confirm',
    cancel: 'cancel',
    addComment: 'add comment',
    seeMore: 'see more',
    link: 'link',
    close: 'close',
  },
  selectInputs: {
    female: 'female',
    male: 'male',
    unkown: 'unkown',
  },
};

export default enJSON;
