import i18n from "i18next";

import { initReactI18next } from "react-i18next";
// import LanguageDetector from 'i18next-browser-languagedetector';
import enJSON from "./translations/en";
import azJSON from "./translations/az";
import ruJSON from "./translations/ru";

const resources = {
  en: { translation: enJSON },
  az: { translation: azJSON },
  ru: { translation: ruJSON },
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    react: { useSuspense: true },
    interpolation: { escapeValue: false },
  });

export default i18n;
