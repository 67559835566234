import { FC, useState, createContext } from 'react';

export type configType = {
  isOpen: boolean;
  message: string;
  type: 'success' | 'info' | 'warning' | 'error';
};
export type AlertType = {
  config: configType;
  handleMessage: (
    open: boolean,
    message: string,
    type: 'success' | 'info' | 'warning' | 'error'
  ) => void;
};

export const AlertContext = createContext<AlertType>({} as AlertType);

export const AlertProvider: FC = ({ children }) => {
  const [config, setConfig] = useState<configType>({
    isOpen: false,
    message: '',
    type: 'error',
  });

  const handleMessage = (
    isOpen: boolean,
    message: string,
    type: 'success' | 'info' | 'warning' | 'error'
  ) => {
    setConfig({
      isOpen,
      message,
      type,
    });
  };

  return (
    <AlertContext.Provider value={{ config, handleMessage }}>
      {children}
    </AlertContext.Provider>
  );
};
